import { Box, Button } from "@outschool/backpack";
import {
  TrackingEvent,
  getFundingProgramProperties,
  makeBannerTrackingName
} from "@outschool/funding-programs-shared";
import { useTranslation } from "@outschool/localization";
import { useTrackEvent } from "@outschool/ui-analytics";
import { getEsaState } from "@outschool/ui-auth";
import { Banner } from "@outschool/ui-legacy-component-library";
import React from "react";

type Props = {
  onExitSession: () => void;
  esaState?: string;
};

export default function EsaBanner({ onExitSession }: Props) {
  const { t } = useTranslation("client\\components\\banners\\EsaBanner");
  const trackEvent = useTrackEvent();
  const trackViewedRef = React.useRef<boolean>(false);
  const esaState = getEsaState();
  const fundingProgramProperties = getFundingProgramProperties(esaState);
  const isClassWalletProgram = fundingProgramProperties?.isClassWallet;
  const fundingProgramName = fundingProgramProperties?.programName;

  const trackingProperties = React.useMemo(
    () => ({ fundingProgram: esaState, component: "EsaBanner" }),
    [esaState]
  );

  React.useEffect(() => {
    if (!trackViewedRef.current) {
      trackViewedRef.current = true;
      trackEvent(
        makeBannerTrackingName(TrackingEvent.BannerView),
        trackingProperties
      );
    }
  }, [trackEvent, trackingProperties]);

  if (!isClassWalletProgram || !fundingProgramName) {
    return null;
  }

  return (
    <Banner data-test-id="esa-banner" ref={trackViewedRef}>
      <Box
        flex
        sx={{
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {t(
          "Use your ClassWallet funds to enroll in {{fundingProgramName}} eligible classes.",
          { fundingProgramName }
        )}
        &nbsp;
        <Button
          variant="link"
          sx={{
            fontSize: "1em"
          }}
          onClick={() => {
            trackEvent(
              makeBannerTrackingName(TrackingEvent.OptOutTouch),
              trackingProperties
            );
            onExitSession();
          }}
        >
          {t("Exit ClassWallet Session")}
        </Button>
        &nbsp;
        {t("to pay by other methods.")}
      </Box>
    </Banner>
  );
}
